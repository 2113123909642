import Footer from "./Footer.js";

function Contact() {
  return (
    <div className="body">
      <header className="header">
        <div className="title">Contact Us</div>
        <div className="buttons">
          <a className="button" href="mailto:info@xndig.com">Email Us</a>
        </div>
      </header>
      <Footer />
    </div>
  );
}

export default Contact;
