import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className={"footer"}>
      <div className={"links"}>
        Xanatos Digital Investment Group, LLC | <Link to="/">Home</Link> |{" "}
        <Link to="/about">About</Link> | <Link to="/contact">Contact</Link>
      </div>
    </footer>
  );
}

export default Footer;
