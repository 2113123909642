import Footer from './Footer.js';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="body">
      <div className="title">Xanatos Digital Investment Group</div>
      <div className="buttons">
        <Link className="button" to="/about">About</Link>
        <Link className="button" to="/contact">Contact Us</Link>
      </div>
      <Footer />
    </div>
  );
}

export default Home;