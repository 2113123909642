import Footer from "./Footer.js";
import image from './assets/qrcode.png';

function About() {
  return (
    <div className="body">
      <header className="header">
        <div className="about-title">About Us</div>
        <div className="about-text">
          The purpose of Xanatos Digital Investment Group, LLC (XNDIG) is to
          purchase, invest in and maintain digital assets and related
          infrastructure such as, but not limited to, Bitcoin Mining and
          Lightning Network routing nodes.
          <br />
          <br />
          XNDIG's Bitcoin mining equipment is distributed internationally to
          mitigate jurisdictional risks and to capture competitive electricity
          costs. Our Lightning Network Node is run via Tor. You can find
          up-to-date node statistics at{" "}
          <a href="https://amboss.space/node/02de73b05723d6e017520d79343a3d794727f4abc77046fc5abd08f865f8dcfaa0">
            amboss.space
          </a>
          . Please feel free to open channels to our node via either the QR code
          or public key information below.
          <br />
          <br />
          <img className="qrcode" src={image} alt="Lightning Network QR Code" />
          <div className="pub-key">
            02de73b05723d6e017520d79343a3d794727f4abc77046fc5abd08f865f8dcfaa0@aeksjojy2qbkrmct42h3tttdykorfis5tf54zm6xyt6bzaufc24gchyd.onion:9735
          </div>
        </div>
      </header>
      <Footer />
    </div>
  );
}

export default About;
